//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles: css({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden'
    }),
    pageTile__tile: css(mq({
        flex: '0 0 100%',
        maxWidth: '100%',
        padding: '10px',
        '&:nth-of-type(even)': {
            '.pageTile__bsyc__bg': mq({
                //order: ['0', null, '2'],
            }),
            '.pageTile__bsyc__content__details': mq({
                //order: '1'
            })
        }
    })),

    tile__content: css({
        '&:hover': {
            textDecoration: 'none',
            '& .tile__flip__wrapper' : {
                backgroundColor: '#DD3430'
            },
            '& .pageTile__bsyc__content__details': {
                '&:after' : {
                    backgroundColor: '#DD3430'
                },
                '&:before' : {
                    background : 'white'
                },
                //backgroundColor : 'white',
                //backgroundColor: variables.colorYellow
                // '&.default' : css({
                //      backgroundColor: 'rgba(0,113,188,1)'
                // }),
                // '&.red' : css({
                //      backgroundColor: 'rgba(211,61,2,1)'
                // }),
                // '&.green' : css({
                //      backgroundColor: 'rgba(0, 146, 69,1)'
                // })
                '& .pageTile__bsyc__title' : {
                    color : 'white'
                },
                '& .pageTile__bsyc__desc' : {
                    color : 'white'
                }
            },
            '& .pageTile__bsyc__bg2': {
                //transform: scale(1.05)
                transform: 'scale(1.05)'
            },
            '& .pageTile__bsyc__learn__more__text' : {
                //backgroundColor : variables.colorRed,
                color : 'white',
                border : '2px solid white',
            } 
        }
    }),

    tile__flip__wrapper: css({
        zIndex: '-1',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        flexWrap: 'wrap',
        backgroundColor: '#F9F8F8',
        //boxShadow : '-1px 1px 5px rgb(190 190 190 / 50%)'
        boxShadow: '1px 2px 10px rgb(190 190 190)'
    }),

    tile__bg: css(mq({
        height: '0',
        paddingTop: ['56.25%', '19%', '33%'],
        //height: ['50%','35%','30%'],
        //flex: '1 1 300px',
        flex: ['1 0 100%',null,'1 0 40%'],
        maxWidth : ['100%',null,'40%'],        
        overflow: 'hidden',
        position: 'relative',

        //transform : 'skewX(-8deg)',
        //transformOrigin: 'top',

        // '&:after' : {
        //     position : 'absolute',
        //     content: '""',
        //     width : '100%',
        //     height: '100%',
        //     backgroundColor: 'red',
        //     transform : 'skewX(-8deg)',
        //     transformOrigin: 'top',
        //     top : '0px'
        // },

    })),

    tile__bg2: css(mq({
        height: '100%',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //overflow:'hidden',
        transition: 'all .2s',
        position: 'absolute',
        top: '0px',
        width: '100%'
    })),

    tile__details: css(mq({
        overflow: 'hidden',
        flexGrow: '1',
        //textAlign: 'center',
        width: '100%',
        bottom: '0',
        //backgroundColor: variables.colorCream,
        
        //flex: '1 1 300px',
        flex: ['1 0 100%',null,'1 0 60%'],
        maxWidth : ['100%',null,'60%'],
        
        transition: 'all .2s ease-in-out',
        position: 'relative',
        transform : ['translateX(0px)',null,'translateX(-52px)'],
        marginTop : ['-11%',null,'unset'],

        '&:before' : {
            position : 'absolute',
            content: '""',
            width : ['100%',null,'1%'],
            height: ['1%',null,'100.1%'],
            backgroundColor: '#F9F8F8',
            transform : ['skewY(-6deg)',null,'skewX(-8deg)'],
            transformOrigin: ['right',null,'bottom'],
            zIndex: 1
            //boxShadow : '-1px 1px 5px rgb(190 190 190 / 50%)'
        },
        '&:after' : mq({
            position : 'absolute',
            content: '""',
            width : '100%',
            height: '100.2%',
            backgroundColor: '#F9F8F8',
            transform : ['skewY(-6deg)',null,'skewX(-8deg)'],
            transformOrigin: ['right',null,'bottom'],
            top : 0,
            zIndex : ['-1',null,'auto']
            //boxShadow : '-1px 1px 5px rgb(190 190 190 / 50%)'
        }),

        '& .pageTile__bsyc__container': mq({
            //position: 'absolute',
            position: ['static', null, 'absolute'],
            //border: '1px solid red',
            width : ['100%',null,'90%'],
            //height: '100%',
            top : '50%',
            left : '55%',
            transform : ['translate(0, 0)',null,'translate(-50%, -50%)'],
            // top: '50%',
            // left: '50%',
            // transform: ['none', null, 'translate(-50%, -50%)'],
            //width: ['100%', null, '90%'],
            //padding: ['1rem', null, '0px'],
            padding : ['1rem',null,'2rem'],
            paddingTop : ['3.5rem',null,'2rem'],
            zIndex: 1,
            //backgroundColor: '#F9F8F8',
            '& .pageTile__bsyc__title': mq({
                //fontFamily: 'proxima-soft, sans-serif',
                fontSize: ['30px',null,'44px'],
                //textTransform: 'uppercase',
                fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                fontFamily: variables.familyHeader,
                //paddingTop: '.5rem',
                //paddingBottom: '.5rem',
                color: '#DD3430',
                //maxWidth: '410px'
            }),
            '& .pageTile__bsyc__desc': mq({
                lineHeight: '1.3',
                //fontSize: '.9rem',
                //color: '#3d3d3d',
                //minHeight: '70px',
                color: '#222223',
                marginTop: ['1rem',null,'1.5rem'],
                fontSize : ['1rem',null,'1.2rem'],
            }),
            '& .pageTile__bsyc__learn__more' : mq({
                marginTop: ['1rem',null,'1.5rem'],
                '.pageTile__bsyc__learn__more__text' : {
                    color: 'white',
                    backgroundColor : '#DD3430',
                    display : 'inline-block',
                    //border : '2px solid black',
                    fontWeight : '700',
                    padding : '0.2rem 2rem',
                    borderRadius: '10px',
                    fontSize : ['1rem',null,'24px']
                }
            })
        })
    }))
}