import React, { useState, useEffect } from "react";
import { useRouter } from "../../../components";
import { useGet } from "../../../components";
import { Item } from "../../items/item";
import { NotFound } from "../../../components";
import env from "../../../env";
import { useStore } from "../../../store";
import { ErrorPopup, Loading } from "../../../components";
import { useInstagram } from "../../../components";
import { MetaTag } from "../../../components";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { gtm } from "../../../lib/tracking";
import jwtDecode from "jwt-decode";
//import * as JWT from 'jwt-decode';

const log = (...args) => {
  //console.log(args);
};
const areEqual = (prevProps, nextProps) => {
  return prevProps.pageData === nextProps.pageData;
};
const PageMemo = React.memo(Page, areEqual);

export function ViewPage() {
  const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [data, setData] = useState(null);
  //const path = usePath();
  //log('view page', path);
  const { pathname, Redirect } = useRouter();
  useInstagram();

  const get = useGet();
  log("0. viewpage ready");

  useEffect(() => {
    log("*. start loading");
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_PAGE", payload: data.data });
      dispatch({ type: "SET_MENU", payload: data.menu });
    }
    // eslint-disable-next-line
  }, [data]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  //log(router.pathname);
  //log(get.loading());
  log("1. viewpage component", pageLoaded, get.response);

  const pageData = get.response.data;
  //const menuData = get.response.menu;
  const redirectTo = get.response.redirectTo;
  console.log("pageData ----- ", pageData);

  const cmsOption = {};
  log(pageLoaded, !pageLoaded && !!pageData);
  if (!pageLoaded && pageData) {
    //log(pageLoaded, pageData, menuData)
    log("2. dispatching page & menu");
    setPageLoaded(true);
    setData(get.response);
    document.title = pageData.pageTitle;
    gtm.pageView(pathname, pageData.pageTitle);
    return null;
  } else if (redirectTo) {
    if (redirectTo.indexOf("http") >= 0 && typeof window !== `undefined`) {
      return (window.location.href = redirectTo);
    } else {
      return <Redirect to={redirectTo} />;
    }
  } else if (!pageLoaded && !pageData) {
    setPageLoaded(true);
    setData(get.response);
    return <NotFound />;
  }

  log("3. call rendering memo");
  if (pageData) {
    const pageType = cmsUtils.payload(pageData, "PageType") || "public";
    if (pageType === "member") {
      const returnUrl = "/members-login";
      const memberAreaToken = sessionStorage.getItem("memberAreaToken");
      if (!memberAreaToken) {
        return <Redirect to={returnUrl} />;
      } else {
        try {
          const decoded = jwtDecode(memberAreaToken);
          console.log("decoded --- ", decoded);
          const currentDate = new Date();
          if (decoded.exp * 1000 < currentDate.getTime()) {
            sessionStorage.removeItem("memberAreaToken");
            return <Redirect to={returnUrl} />;
          }
        } catch (error) {
          sessionStorage.removeItem("memberAreaToken");
          return <Redirect to={returnUrl} />;
        }
      }
    }
    return <PageMemo pageData={pageData} cmsOption={cmsOption} />;
  } else {
    return <NotFound />;
  }
}

function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  cmsOption.pageData = pageData;
  // document.title = pageData.pageTitle;
  log("4. page rendering", pageData, cmsOption);
  const sectionItems = pageData.sections.map((section, index) => {
    return (
      <Section
        item={section}
        key={section.sectionName + index}
        cmsOption={cmsOption}
      ></Section>
    );
  });
  return (
    <>
      <MetaTag data={getMetaTag(pageData)} />
      <div className={`cmsPage cmsPage--${pageData.pageTypeCode}`}>
        {sectionItems}
      </div>
    </>
  );
}

function Section(props) {
  log("5. section rendering");
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0) return <></>;

  const subItemsElts = subItems.map((subItem, index) => (
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  ));
  return <>{subItemsElts}</>;
}

function getMetaTag(pageData) {
  //const country = utils.site.isNZ? 'New Zealand' : 'Australia'
  const country = "Australia";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;

  return {
    title: pageData.pageTitle + " | " + titleSuffix,
    description: cmsUtils.payload(pageData, "Description"),
    keywords: cmsUtils.payload(pageData, "Keyword"),
    heroImageUrl: utils.site.fullUrl(cmsUtils.payload(pageData, "ImageUrl")),
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(pageData.pageUrl),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}
