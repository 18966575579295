const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorGray : '#555555',
  colorRed : '#DD3430',
  colorCream : '#F1F1F1',
  colorLightBlack : '#222223',
  colorDarkGray : '#333333',
  colorLightGray : '#727172'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"ff-enzo-web",serif',

  primaryColor: colors.colorRed,
  fontColor: colors.colorLightBlack,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;