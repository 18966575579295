/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, Fragment, useEffect } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";
import env from "../../env";
import { useStore } from "../../store";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { state, dispatch } = useStore();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useEffect(() => {
    dispatch({ type: "SET_STATE", payload: { memberArea: "" } });
    const abortController = new AbortController();
    const memberAreaToken = sessionStorage.getItem("memberAreaToken") || "";

    const fetchData = async () => {
      const res = await fetch(
        env.apiBase + "/api/contact/checkmemberarealogin",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${memberAreaToken}`,
          },
          signal: abortController.signal,
          //body: JSON.stringify(sendData)
        }
      );

      if (!res.ok) {
        //throw new Error(`HTTP error! status: ${res.status}`);
        return;
      }
      const data = await res.json();
      if (data === "ok") {
        dispatch({ type: "SET_STATE", payload: { memberArea: "ok" } });
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <header css={[style.top, scrolled && style.topScrolled]} id='top'>
        <BackButton />
        <SiteLink
          to='/'
          css={[style.logo, scrolled && style.logoScrolled]}
          onClick={backTop}
        >
          {/* <img css={style.logoImg} src="/assets/logos/logo.png" alt="logo" /> */}
          <img
            css={style.logoImg}
            src={
              scrolled && style.logoScrolled
                ? "/assets/logos/logo2.png"
                : "/assets/logos/logo.png"
            }
            alt='logo'
          />
        </SiteLink>

        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />

        {/* <div css={style.contact__us__btn__c}>
          <SiteLink to="https://sarvra-members.stg5.jaba.com.au">
            <button className='btn btn-primary' css={style.contact__us__btn__top}>
              Member Login
            </button>
            <div className='contact__us__btn__icon' css={style.contact__us__btn__icon}>
              <img src='/assets/member-login-icon.png' style={{ width: '100%' }} alt='member_icon' />
            </div>
          </SiteLink>
        </div> */}

        {state.memberArea && state.memberArea === "ok" ? (
          <Fragment>
            <button
              className='btn btn-primary'
              css={style.contact__us__btn__top}
            >
              <SiteLink to='/members-area'>
                <span>Member Area </span>
                <img
                  src='/assets/member-login-icon.png'
                  css={style.contact__us__btn__icon}
                  alt='member_icon'
                />
              </SiteLink>
            </button>
            <button
              className='btn btn-primary'
              css={style.contact__us__btn__top__logout}
              onClick={() => {
                sessionStorage.removeItem("memberAreaToken");
                window.location.href = "/members-login";
              }}
            >
              Logout
            </button>
          </Fragment>
        ) : (
          <button className='btn btn-primary' css={style.contact__us__btn__top}>
            <SiteLink to='/members-login'>
              <span> Member Area Login </span>
              <img
                src='/assets/member-login-icon.png'
                css={style.contact__us__btn__icon}
                alt='member_icon'
              />
            </SiteLink>
          </button>
        )}
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}
