import { css } from "@emotion/core";
import { mq, variables } from "../../cssInJs";

export default {
  top: css(
    mq({
      //height: [44, null, 80],
      height: [44, null, 56],
      background: "transparent",
      position: "fixed",
      width: "100%",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s",
    })
  ),
  topScrolled: css(
    mq({
      //height: [44, null, 56],
      background: variables.colorCream
    })
  ),
  logo: css(mq({
    position: 'fixed',
    left: ['22px', null, '110px'],
    transition: "all .5s",
  })),
  logoImg: css(
    mq({
      display: "block",
      height: [80, null, 150],
      marginTop: ["2rem", null, "4rem"],
      transition: "all .5s",
    })
  ),
  logoScrolled: css(
    mq({
      left: ['52px', null, '110px'],
      img: css(
        mq({
          height: [38, null, 50],
          marginTop: "0rem",
        })
      ),
    })
  ),

  contact__us__btn__c: mq({
    position: 'fixed',
    right: ['60px', null, '110px'],
  }),

  contact__us__btn__icon: {
    width: '20px',
    marginLeft: '0.5rem'
  },

  contact__us__btn__top: mq({
    position: 'fixed',
    right: ['50px','60px', '110px', '110px'],
    fontSize: '0.7rem',
    fontWeight: 700,
    padding: '0.3rem 0.8rem',
    //backgroundColor: variables.co,
    borderRadius: '4px',
    'a': {
      color: 'white',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }),

  contact__us__btn__top__logout: mq({
    position: 'fixed',
    right: ['180px', '200px', '250px', '280px'],
    fontSize: '0.7rem',
    fontWeight: 700,
    padding: ['0.45rem 0.8rem', null, '0.45rem 0.8rem', '0.3rem 0.8rem'],
    //backgroundColor: variables.co,
    borderRadius: '4px',
  })
  
};
