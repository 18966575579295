/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import utils from "utils";
import style from "./memberLoginStyle";
import { getMemberLoginModel } from "./memberLoginModel";
import { useForm } from "../../../../form";
import { usePost, Button, ErrorMessage } from "../../../../components";
import env from "../../../../env";
import { useStore } from "../../../../store";

export function MemberLogin(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "member_login",
    item.cssClass || item.anchorName || ""
  );
  const model = getMemberLoginModel({});
  const form = useForm(model, { usePlaceholder: true });
  const post = usePost();
  const render = (name) => form.renderControl(name, null);
  const [sendingStatus, setSendingStatus] = useState(null);
  const { state } = useStore();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // Check session storage for token on component mount
    const token = sessionStorage.getItem("memberAreaToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);
  //const [buttonTrigger, setButtonTrigger] = useState(0);

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    form.validateForm(e, () => {
      const value = { ...form.getValue() };
      post.send(env.apiBase + "/api/contact/memberarealogin", value);
      setSendingStatus("pending");
    });
  }

  function handleLogout() {
    sessionStorage.removeItem("memberAreaToken");
    setIsLoggedIn(false);
    window.location.href = "/members-login";
  }

  // useEffect(() => {
  //   if (buttonTrigger === 0) {
  //     return;
  //   }

  //   const fetchData = async () => {
  //     const memberAreaToken = localStorage.getItem("memberAreaToken");
  //     const res = await fetch(env.apiBase + "/api/contact/memberarealogin2", {
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${memberAreaToken}`,
  //       },
  //       //body: JSON.stringify(info)
  //     });
  //     const data = await res.json();
  //     console.log(data);
  //   };

  //   fetchData();
  // }, [buttonTrigger]);

  if (cmsOption.isCmsEdit) {
    return (
      <div
        className={cssClass}
        css={style.member_login}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <pre
          style={{
            fontSize: "12px",
            margin: "5px",
            padding: "10px",
            background: "#eee",
            border: "1px solid #ccc",
          }}
        >
          <b> Member Login </b>
        </pre>
      </div>
    );
  }

  const data = post.response;

  if (post.done() && sendingStatus === "pending") {
    //var value = form.getValue();
    form.reset(model);
    setSendingStatus(null);
    if (
      data &&
      data.results &&
      data.results.token &&
      data.results.token !== ""
    ) {
      // localStorage.setItem("memberAreaToken", data.results.token);
      sessionStorage.setItem("memberAreaToken", data.results.token);
      setIsLoggedIn(true);
      setTimeout(() => {
        window.location.href = "/members-area";
      }, 500);
    }
  }

  return (
    <div
      className={cssClass}
      css={style.member_login}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      {isLoggedIn || (state.memberArea && state.memberArea === "ok") ? (
        <div style={{ textAlign: "center" }}>
          <button className='btn btn-primary' onClick={handleLogout}>
            {" "}
            Logout
          </button>
        </div>
      ) : (
        <form
          className='member_login_form'
          style={{ maxWidth: "420px", margin: "auto" }}
        >
          <div className='form-row'>
            <div className='col-md-12 mb-2'>{render("email")}</div>
          </div>
          <div className='form-row'>
            <div className='col-md-12 mb-2'>
              <div className='actions'>
                <Button onClick={onSubmit} status={post.status}>
                  Login
                </Button>
                <ErrorMessage
                  errors={form.errors}
                  summaryMessage='Please review the errors.'
                />
                <ErrorMessage errors={post.errors} />
                {post.done() && (
                  <span
                    style={{
                      marginLeft: "1.5rem",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    Successfully Login!
                  </span>
                )}
              </div>
            </div>
          </div>
        </form>
      )}

      {/* <div>
        <Button onClick={() => {
          setButtonTrigger(buttonTrigger + 1);
        }}>
                    adfsdfsfsfd
        </Button>
      </div> */}
    </div>
  );
}
